import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from '../../services/api.service'

export const getServicePackageList = createAsyncThunk(
    'getServicePackageList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getServicePackageList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

// let currentAbortController = null; // Initially set to null

// export const getServicePackageList = createAsyncThunk(
//   'getServicePackageList',
//   async (payload, thunkAPI) => {
//     try {
//       // Check if there's a request in progress and if it's not already aborted
//       if (currentAbortController && !currentAbortController.signal.aborted) {
//         currentAbortController.abort();  // Abort the previous request
//       }

//       // Create a new AbortController for the new request
//       currentAbortController = new AbortController();
//       const signal = currentAbortController.signal;

//       // Make the API request with the new signal
//       const response = await apiService.getServicePackageList(payload, { signal });

//       // Clear the controller after the request completes
//       currentAbortController = null; // Reset controller after successful request

//       return response;
//     } catch (error) {
//       if (error.name === 'AbortError') {
//       } else {
//         // Handle any other errors here
//         currentAbortController = null; // Reset controller if there's an error
//         return thunkAPI.rejectWithValue(error);
//       }
//     }
//   }
// );

export const getServiceAlacarteList = createAsyncThunk(
    'getServiceAlacarteList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getServiceAlacarteList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createService = createAsyncThunk(
    'createService',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createService(payload.data)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getServiceInfo = createAsyncThunk(
    'getServiceInfo',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getServiceInfo(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateService = createAsyncThunk(
    'updateService',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateService(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const toggleService = createAsyncThunk(
    'toggleService',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.toggleService(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getCategoryList = createAsyncThunk(
    'getCategoryList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getCategoryList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createCategory = createAsyncThunk(
    'createCategory',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createCategory(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getCategoryInfo = createAsyncThunk(
    'getCategoryInfo',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getCategoryInfo(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateCategory = createAsyncThunk(
    'updateCategory',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateCategory(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const deleteCategory = createAsyncThunk(
    'deleteCategory',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.deleteCategory(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const toggleCategory = createAsyncThunk(
    'toggleCategory',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.toggleCategory(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getBrandList = createAsyncThunk(
    'getBrandList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getBrandList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createBrand = createAsyncThunk(
    'createBrand',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createBrand(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getBrandInfo = createAsyncThunk(
    'getBrandInfo',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getBrandInfo(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateBrand = createAsyncThunk(
    'updateBrand',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateBrand(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const deleteBrand = createAsyncThunk(
    'deleteBrand',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.deleteBrand(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const toggleBrand = createAsyncThunk(
    'toggleBrand',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.toggleBrand(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getModelList = createAsyncThunk(
    'getModelList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getModelList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createModel = createAsyncThunk(
    'createModel',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createModel(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getModelInfo = createAsyncThunk(
    'getModelInfo',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getModelInfo(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateModel = createAsyncThunk(
    'updateModel',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateModel(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const deleteModel = createAsyncThunk(
    'deleteModel',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.deleteModel(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const toggleModel = createAsyncThunk(
    'toggleModel',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.toggleModel(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getInstallerList = createAsyncThunk(
    'getInstallerList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getInstallerList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createInstaller = createAsyncThunk(
    'createInstaller',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createInstaller(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getInstallerInfo = createAsyncThunk(
    'getInstallerInfo',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getInstallerInfo(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateInstaller = createAsyncThunk(
    'updateInstaller',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateInstaller(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const deleteInstaller = createAsyncThunk(
    'deleteInstaller',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.deleteInstaller(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getInstallationList = createAsyncThunk(
    'getInstallationList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getInstallationList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const createInstallation = createAsyncThunk(
    'createInstallation',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.createInstallation(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getInstallationInfo = createAsyncThunk(
    'getInstallationInfo',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getInstallationInfo(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateInstallation = createAsyncThunk(
    'updateInstallation',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateInstallation(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const deleteInstallation = createAsyncThunk(
    'deleteInstallation',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.deleteInstallation(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getWindscreenList = createAsyncThunk(
    'getWindscreenList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getWindscreenList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateWindscreen = createAsyncThunk(
    'updateWindscreen',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateWindscreen(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getInsuranceList = createAsyncThunk(
    'getInsuranceList',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.getInsuranceList(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateInsurance = createAsyncThunk(
    'updateInsurance',
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.updateInsurance(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const initialData = {
    otherList: [],
    accessoriesList: [],
    categoryList: [],
    brandList: [],
    brandInfo: {},
    modelList: [],

    servicePackageList: [],
    serviceAlacarteList: [],
    serviceInfo: {},
    otherInfo: [],
    installOptions: [],

    formLoading: false,
    listLoading: true,
    packageListLoading: true,
    updateLoading: false,
    toggleLoading: false,
    createLoading: false,

    packageToggle: false,
    toggle: false,

    packageSort: {
        activePage: 1,
        start: 0,
        length: 5,
        search: '',
        total: 0,
        body_type: '',
    },
    alacarteSort: {
        activePage: 1,
        start: 0,
        length: 5,
        search: '',
        total: 0,
        body_type: '',
    },
    accessoriesSort: {
        activePage: 1,
        start: 0,
        length: 10,
        search: '',
        total: 0,
    },
    otherSort: {
        activePage: 1,
        start: 0,
        length: 10,
        search: '',
        total: 0,
    },
    updateRequestLoading: false,
}

const dataSlice = createSlice({
    name: 'service/data',
    initialState: initialData,
    reducers: {
        setPackageSort: (state, action) => {
            state.packageSort = action.payload
        },
        setAlacarteSort: (state, action) => {
            state.alacarteSort = action.payload
        },
        setAccessoriesSort: (state, action) => {
            state.accessoriesSort = action.payload
        },
        setOtherSort: (state, action) => {
            state.otherSort = action.payload
        },
        setListLoading: (state, action) => {
            state.listLoading = action.payload
        },
        setPackageToggle: (state, action) => {
            state.packageToggle = action.payload
        },
        setToggle: (state, action) => {
            state.toggle = action.payload

            if (action.payload === false) {
                state.updateRequestLoading = false
            }
        },
    },
    extraReducers: {
        //list
        [getServicePackageList.rejected]: (state) => {
            state.packageListLoading = false
        },
        [getServicePackageList.fulfilled]: (state, action) => {
            state.packageListLoading = false
            state.servicePackageList = action.payload.data.aaData
            state.packageSort.total = action.payload.data.iTotalDisplayRecords
        },
        [getServicePackageList.pending]: (state) => {
            state.packageListLoading = true
        },

        [getServiceAlacarteList.rejected]: (state) => {
            state.listLoading = false
        },
        [getServiceAlacarteList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.serviceAlacarteList = action.payload.data.aaData
            state.alacarteSort.total = action.payload.data.iTotalDisplayRecords

            state.accessoriesList = action.payload.data.aaData
            state.accessoriesSort.total =
                action.payload.data.iTotalDisplayRecords
        },
        [getServiceAlacarteList.pending]: (state) => {
            state.listLoading = true
        },

        [getCategoryList.rejected]: (state) => {
            state.listLoading = false
        },
        [getCategoryList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.categoryList = action.payload.data.aaData
            state.accessoriesSort.total =
                action.payload.data.iTotalDisplayRecords
        },
        [getCategoryList.pending]: (state) => {
            state.listLoading = true
        },

        [getBrandList.rejected]: (state) => {
            state.listLoading = false
        },
        [getBrandList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.brandList = action.payload.data.aaData
            state.accessoriesSort.total =
                action.payload.data.iTotalDisplayRecords
        },
        [getBrandList.pending]: (state) => {
            state.listLoading = true
        },

        [getModelList.rejected]: (state) => {
            state.listLoading = false
        },
        [getModelList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.modelList = action.payload.data.aaData
            state.accessoriesSort.total =
                action.payload.data.iTotalDisplayRecords
        },
        [getModelList.pending]: (state) => {
            state.listLoading = true
        },

        [getInstallerList.rejected]: (state) => {
            state.listLoading = false
        },
        [getInstallerList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.otherList = action.payload.data.aaData
            state.otherSort.total = action.payload.data.iTotalDisplayRecords

            const installerList = action.payload.data.aaData
            const installerOptions =
                installerList?.length > 0
                    ? installerList.map((installer) => ({
                          label: installer.name,
                          value: installer.id,
                      }))
                    : []

            state.installerOptions = installerOptions
        },
        [getInstallerList.pending]: (state) => {
            state.listLoading = true
        },

        [getInstallationList.rejected]: (state) => {
            state.listLoading = false
        },
        [getInstallationList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.otherList = action.payload.data.aaData
            state.otherSort.total = action.payload.data.iTotalDisplayRecords

            const installList = action.payload.data.aaData

            const installOptions =
                installList?.length > 0
                    ? installList.map((install) => ({
                          label: install.service_name,
                          value: install.id,
                          price: install.commission_amount,
                          type: install.commission_type,
                      }))
                    : []

            state.installOptions = installOptions
        },
        [getInstallationList.pending]: (state) => {
            state.listLoading = true
        },

        [getWindscreenList.rejected]: (state) => {
            state.listLoading = false
        },
        [getWindscreenList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.otherList = action.payload.data.aaData
            state.otherSort.total = action.payload.data.iTotalDisplayRecords
        },
        [getWindscreenList.pending]: (state) => {
            state.listLoading = true
        },

        [getInsuranceList.rejected]: (state) => {
            state.listLoading = false
        },
        [getInsuranceList.fulfilled]: (state, action) => {
            state.listLoading = false
            state.otherList = action.payload.data.aaData
            state.otherSort.total = action.payload.data.iTotalDisplayRecords
        },
        [getInsuranceList.pending]: (state) => {
            state.listLoading = true
        },

        //info
        [getServiceInfo.rejected]: (state) => {
            state.formLoading = false
        },
        [getServiceInfo.fulfilled]: (state, action) => {
            state.formLoading = false
            state.serviceInfo = action.payload.data
        },
        [getServiceInfo.pending]: (state) => {
            state.formLoading = true
        },

        [getCategoryInfo.rejected]: (state) => {
            state.formLoading = false
        },
        [getCategoryInfo.fulfilled]: (state, action) => {
            state.formLoading = false
            state.categoryInfo = action.payload.data
        },
        [getCategoryInfo.pending]: (state) => {
            state.formLoading = true
        },

        [getBrandInfo.rejected]: (state) => {
            state.formLoading = false
        },
        [getBrandInfo.fulfilled]: (state, action) => {
            state.formLoading = false
            state.brandInfo = action.payload.data
        },
        [getBrandInfo.pending]: (state) => {
            state.formLoading = true
        },

        [getModelInfo.rejected]: (state) => {
            state.formLoading = false
        },
        [getModelInfo.fulfilled]: (state, action) => {
            state.formLoading = false
            state.modelInfo = action.payload.data
        },
        [getModelInfo.pending]: (state) => {
            state.formLoading = true
        },

        [getInstallerInfo.rejected]: (state) => {
            state.formLoading = false
        },
        [getInstallerInfo.fulfilled]: (state, action) => {
            state.formLoading = false
            state.otherInfo = action.payload.data
        },
        [getInstallerInfo.pending]: (state) => {
            state.formLoading = true
        },

        [getInstallationInfo.rejected]: (state) => {
            state.formLoading = false
        },
        [getInstallationInfo.fulfilled]: (state, action) => {
            state.formLoading = false
            state.otherInfo = action.payload.data
        },
        [getInstallationInfo.pending]: (state) => {
            state.formLoading = true
        },

        //create
        [createService.rejected]: (state) => {
            state.createLoading = false
        },
        [createService.fulfilled]: (state, action) => {
            state.createLoading = false
        },
        [createService.pending]: (state) => {
            state.createLoading = true
        },

        [createCategory.rejected]: (state) => {
            state.createLoading = false
        },
        [createCategory.fulfilled]: (state, action) => {
            state.createLoading = false
        },
        [createCategory.pending]: (state) => {
            state.createLoading = true
        },

        [createBrand.rejected]: (state) => {
            state.createLoading = false
        },
        [createBrand.fulfilled]: (state, action) => {
            state.createLoading = false
        },
        [createBrand.pending]: (state) => {
            state.createLoading = true
        },

        [createModel.rejected]: (state) => {
            state.createLoading = false
        },
        [createModel.fulfilled]: (state, action) => {
            state.createLoading = false
        },
        [createModel.pending]: (state) => {
            state.createLoading = true
        },

        [createInstallation.rejected]: (state) => {
            state.createLoading = false
        },
        [createInstallation.fulfilled]: (state, action) => {
            state.createLoading = false
        },
        [createInstallation.pending]: (state) => {
            state.createLoading = true
        },

        [createInstaller.rejected]: (state) => {
            state.createLoading = false
        },
        [createInstaller.fulfilled]: (state, action) => {
            state.createLoading = false
        },
        [createInstaller.pending]: (state) => {
            state.createLoading = true
        },

        //update
        [updateService.rejected]: (state) => {
            state.updateLoading = false
        },
        [updateService.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updateService.pending]: (state) => {
            state.updateLoading = true
        },

        [updateCategory.rejected]: (state) => {
            state.updateLoading = false
        },
        [updateCategory.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updateCategory.pending]: (state) => {
            state.updateLoading = true
        },

        [updateBrand.rejected]: (state) => {
            state.updateLoading = false
        },
        [updateBrand.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updateBrand.pending]: (state) => {
            state.updateLoading = true
        },

        [updateModel.rejected]: (state) => {
            state.updateLoading = false
        },
        [updateModel.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updateModel.pending]: (state) => {
            state.updateLoading = true
        },

        [updateInstallation.rejected]: (state) => {
            state.updateLoading = false
        },
        [updateInstallation.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updateInstallation.pending]: (state) => {
            state.updateLoading = true
        },

        [updateInstaller.rejected]: (state) => {
            state.updateLoading = false
        },
        [updateInstaller.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [updateInstaller.pending]: (state) => {
            state.updateLoading = true
        },

        [updateWindscreen.rejected]: (state) => {
            // state.updateRequestLoading = false
        },
        [updateWindscreen.fulfilled]: (state, action) => {},
        [updateWindscreen.pending]: (state) => {
            state.updateRequestLoading = true
        },

        [updateInsurance.rejected]: (state) => {
            // state.updateRequestLoading = false
        },
        [updateInsurance.fulfilled]: (state, action) => {},
        [updateInsurance.pending]: (state) => {
            state.updateRequestLoading = true
        },

        //toggle
        [toggleService.rejected]: (state) => {
            state.toggleLoading = false
        },
        [toggleService.fulfilled]: (state, action) => {
            state.toggleLoading = false
        },
        [toggleService.pending]: (state) => {
            state.toggleLoading = true
        },

        [toggleBrand.rejected]: (state) => {
            state.toggleLoading = false
        },
        [toggleBrand.fulfilled]: (state, action) => {
            state.toggleLoading = false
        },
        [toggleBrand.pending]: (state) => {
            state.toggleLoading = true
        },

        [toggleModel.rejected]: (state) => {
            state.toggleLoading = false
        },
        [toggleModel.fulfilled]: (state, action) => {
            state.toggleLoading = false
        },
        [toggleModel.pending]: (state) => {
            state.toggleLoading = true
        },

        [toggleCategory.rejected]: (state) => {
            state.toggleLoading = false
        },
        [toggleCategory.fulfilled]: (state, action) => {
            state.toggleLoading = false
        },
        [toggleCategory.pending]: (state) => {
            state.toggleLoading = true
        },
    },
})

export const {
    setPackageSort,
    setAlacarteSort,
    setAccessoriesSort,
    setOtherSort,
    setListLoading,
    setPackageToggle,
    setToggle,
} = dataSlice.actions

export default dataSlice.reducer
