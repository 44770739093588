//lib
import axios from 'axios'
import apiService from './api.service'
import { Notification, toast } from 'demo/components/ui'

function handleErrorResponse(error) {
    const authError = error.response && error.response?.status === 401
    // const versionUpdateError = error.response && error.response.status === 410;
    const manyAttemptsError = error.response && error.response?.status === 429
    const unexpectedError = error.response && error.response?.status === 500
    // const maintainanceError = error.response && error.response.status === 503;
    // const networkError = !error.response;

    // const merchantName = localStorage.getItem('merchant_name');

    // // handle no network
    // if (networkError) {
    //   toast.error('Server is busy right now. Please refresh and try again later.');
    // }

    //handle 401
    if (authError) {
        localStorage.clear()

        setTimeout(() => {
            toast.push(
                <Notification type="danger" title="Error">
                    Your session has expired. Please log in again to continue.
                </Notification>
            )
            window.location = '/'
        }, 100)
    }

    //   setTimeout(() => {
    //     window.location = '/';
    //   }, 0);
    // }

    // //handle 410
    // if(versionUpdateError) {
    //   toast.success('New version found!')
    //   window.location = `?open=update_version`;
    // }

    // handle 429
    if (manyAttemptsError) {
        toast.push(
            <Notification type="danger" title="Error">
                Too many attempts, please try again later
            </Notification>
        )
    }

    // handle 500
    if (unexpectedError) {
        toast.push(
            <Notification type="danger" title="Error">
                Oops! Something went wrong unexpectedly. Please contact support
                for assistance.
            </Notification>
        )
    }

    // // handle 503
    // if (maintainanceError) {
    //   window.location = `?open=under_maintenance`;
    // }

    return Promise.reject(error)
}

function initializeInterceptors() {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return handleErrorResponse(error)
        }
    )
}

axios.interceptors.request.use(
    (config) => config,
    (error) => {
        return Promise.reject(error)
    }
)

function setToken(token) {
    axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
        Accept: `application/json`,
        // "user-version": process.env.REACT_APP_VERSION,
    }
}

const httpService = {
    get: axios.get,
    patch: axios.patch,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setToken,
    initializeInterceptors,
}

export default httpService
