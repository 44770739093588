import React, { useRef, useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import cloneDeep from 'lodash/cloneDeep'
import FileItem from './FileItem'
import Button from '../Buttons'
import CloseButton from '../CloseButton'
import Notification from '../Notification'
import toast from '../toast'
import { useDispatch, useSelector } from 'react-redux'
import { deleteMedia, setHeicLoading } from 'demo/store/commonReducer'
import ConfirmDialog from '../../shared/ConfirmDialog'
import heic2any from 'heic2any'

const filesToArray = (files) => Object.keys(files).map((key) => files[key])

const Upload = React.forwardRef((props, ref) => {
    const {
        customise,
        datatable,
        beforeAfter,
        accept,
        beforeUpload,
        maxFileSize,
        disabled,
        disabledEdit,
        draggable,
        fileList,
        multiple,
        onChange,
        onFileRemove,
        showList,
        tip,
        uploadLimit,
        children,
        className,
        field,
        form,
        minHeight,
        ...rest
    } = props

    const dispatch = useDispatch()
    const fileInputField = useRef(null)
    const [files, setFiles] = useState(fileList)
    const [dragOver, setDragOver] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)

    const { deleteLoading } = useSelector((state) => state.common)

    const { themeColor, primaryColorLevel } = useConfig()

    useEffect(() => {
        setFiles(fileList)
    }, [fileList])

    const triggerMessage = (msg) => {
        toast.push(
            <Notification type="danger" duration={2000}>
                {msg || 'Upload Failed!'}
            </Notification>,
            {
                placement: 'top-center',
            }
        )
    }

    const pushFile = (newFiles, file) => {
        for (let f of newFiles) {
            file.push(f)
        }
        return file
    }

    const addNewFiles = (newFiles) => {
        let file = cloneDeep(files)
        if (typeof uploadLimit === 'number' && uploadLimit !== 0) {
            if (Object.keys(file).length >= uploadLimit) {
                if (uploadLimit === 1) {
                    file.shift()
                    file = pushFile(newFiles, file)
                }

                return filesToArray({ ...file })
            }
        }
        file = pushFile(newFiles, file)
        return filesToArray({ ...file })
    }

    const checkUploadFile = (file) => {
        let valid = true

        const maxSize = maxFileSize

        for (let f of file) {
            if (!f.type?.startsWith('image/')) {
                valid = 'Please upload image files only!'
            }

            if (f.size >= maxSize) {
                valid = 'Upload file size cannot more then 2MB!'
            }
        }

        return valid
    }

    const onNewFileUpload = async (e) => {
        const { files: newFiles } = e.target
        let result = true
        let processedFiles = []

        if (beforeUpload) {
            result = beforeUpload(newFiles, files)

            if (result === false) {
                triggerMessage()
                return
            }

            if (typeof result === 'string' && result.length > 0) {
                triggerMessage(result)
                return
            }
        }

        if (maxFileSize && accept) {
            result = checkUploadFile(newFiles, files)

            if (result === false) {
                triggerMessage()
                return
            }

            if (typeof result === 'string' && result.length > 0) {
                triggerMessage(result)
                return
            }
        }

        if (beforeAfter) {
            const MAX_TOTAL_SIZE = 25 * 1024 * 1024

            const totalSize = [...files, ...newFiles].reduce(
                (acc, file) => acc + file.size,
                0
            )

            if (totalSize > MAX_TOTAL_SIZE) {
                triggerMessage('Total files size should be less than 25 MB')
                return
            }
        }

        for (let f of newFiles) {
            const fileName = f.name.toLowerCase()
            if (fileName.endsWith('.heic')) {
                dispatch(setHeicLoading(true))
                try {
                    const convertedBlob = await heic2any({
                        blob: f,
                        toType: 'image/png',
                    })
                    const convertedFileName = f.name.replace(/\.heic$/i, '.png')
                    const convertedFile = new File(
                        [convertedBlob],
                        convertedFileName,
                        {
                            type: 'image/png',
                        }
                    )

                    if (convertedFile) {
                        dispatch(setHeicLoading(false))
                    }
                    processedFiles.push(convertedFile)
                } catch (error) {
                    console.error('Error converting HEIC file:', error)
                }
            } else {
                processedFiles.push(f)
            }
        }

        if (result && processedFiles.length > 0) {
            let updatedFiles = addNewFiles(processedFiles)
            // setFiles(updatedFiles);
            onChange?.(updatedFiles, files)
        }
    }

    const onDeleteConfirmation = (fileIndex, id) => {
        setDeleteId({ fileIndex, id })
        setDeleteConfirmationOpen(true)
    }

    const onDeleteConfirmationClose = () => {
        setDeleteConfirmationOpen(false)
    }

    const removeFile = (fileIndex) => {
        if (deleteId.id) {
            dispatch(deleteMedia({ id: deleteId.id }))
                .unwrap()
                .then((res) => {
                    onDeleteConfirmationClose()
                    toast.push(
                        <Notification type="success">
                            Selected attachment is successfully deleted
                        </Notification>
                    )

                    const deletedFileList = files.filter(
                        (_, index) => index !== deleteId.fileIndex
                    )
                    setFiles(deletedFileList)
                    onFileRemove?.(deletedFileList)
                })
                .catch((ex) => {
                    if (ex && ex.response?.status === 422) {
                        const errors = ex.response.data.errors
                        if (errors && Object.keys(errors).length > 0) {
                            Object.keys(errors).map((item, i) => {
                                toast.push(
                                    <Notification type="danger">
                                        {errors[item][0]}
                                    </Notification>
                                )
                            })
                        }
                    }
                })
        } else {
            const deletedFileList = files.filter(
                (_, index) => index !== fileIndex
            )
            setFiles(deletedFileList)
            onFileRemove?.(deletedFileList)
        }
    }

    const triggerUpload = (e) => {
        if (!disabled) {
            fileInputField.current?.click()
        }
        e.stopPropagation()
    }

    const renderChildren = () => {
        if (!draggable && !children) {
            return (
                <Button disabled={disabled} onClick={(e) => e.preventDefault()}>
                    Upload
                </Button>
            )
        }

        if (draggable && !children) {
            return <span>Choose a file or drag and drop here</span>
        }

        return children
    }

    const handleDragLeave = useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const handleDragOver = useCallback(() => {
        if (draggable && !disabled) {
            setDragOver(true)
        }
    }, [draggable, disabled])

    const handleDrop = useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const draggableProp = {
        onDragLeave: handleDragLeave,
        onDragOver: handleDragOver,
        onDrop: handleDrop,
    }

    const draggableEventFeedbackClass = `border-${themeColor}-${primaryColorLevel}`

    const uploadClass = classNames(
        'upload',
        draggable && `upload-draggable`,
        draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
        draggable && disabled && 'disabled',
        dragOver && draggableEventFeedbackClass,
        className
    )

    const uploadInputClass = classNames(
        'upload-input',
        draggable && `draggable`
    )

    return (
        <>
            {((files.length === 0 && customise) || !customise) &&
                !disabledEdit && (
                    <div
                        ref={ref}
                        className={uploadClass}
                        style={minHeight ? { minHeight: minHeight } : {}}
                        {...(draggable
                            ? draggableProp
                            : { onClick: triggerUpload })}
                        {...rest}
                    >
                        <input
                            className={uploadInputClass}
                            type="file"
                            ref={fileInputField}
                            onChange={onNewFileUpload}
                            disabled={disabled}
                            multiple={multiple}
                            accept={accept}
                            title=""
                            value=""
                            {...field}
                            {...rest}
                        ></input>
                        {renderChildren()}
                    </div>
                )}
            {tip}
            {(!customise || (customise && files.length > 0)) && showList && (
                <div
                    className={`upload-file-list ${
                        (files.length > 0 && !minHeight) || disabledEdit
                            ? 'm-0'
                            : ''
                    } `}
                >
                    {files.map((file, index) => (
                        <FileItem
                            file={file}
                            key={file.name + index}
                            customise={customise}
                            datatable={datatable}
                        >
                            {!disabledEdit && (
                                <CloseButton
                                    onClick={() =>
                                        file.image_id
                                            ? onDeleteConfirmation(
                                                  index,
                                                  file.image_id
                                              )
                                            : removeFile(index)
                                    }
                                    className={`upload-file-remove ${
                                        customise ? 'px-1' : ''
                                    }`}
                                />
                            )}
                        </FileItem>
                    ))}
                    <ConfirmDialog
                        isOpen={deleteConfirmationOpen}
                        onClose={onDeleteConfirmationClose}
                        onRequestClose={onDeleteConfirmationClose}
                        type="danger"
                        title="Remove image"
                        onCancel={onDeleteConfirmationClose}
                        onConfirm={removeFile}
                        confirmButtonColor="red-600"
                        disabled={deleteLoading}
                    >
                        <p> Are you sure you want to remove this image? </p>
                    </ConfirmDialog>
                </div>
            )}
        </>
    )
})

Upload.propTypes = {
    uploadLimit: PropTypes.number,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
    showList: PropTypes.bool,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Upload.defaultProps = {
    draggable: false,
    showList: true,
    disabled: false,
    fileList: [],
}

export default Upload
